import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import {
  chooseLoan,
  getAssociatedLoanProductsBasic,
} from '../../../../../actions'

const { Dialog, Autocomplete } = components

const ChangeLoanProductModal = ({
  dispatch,
  loanApplicationId,
  isOpen,
  onClose,
  setModalOpen,
  loanName,
}) => {
  const { t: translate } = useTranslation()
  const [evaluatedLoanProducts, setEvaluatedLoanProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedLoan, setSelectedLoan] = useState(undefined)

  useEffect(() => {
    const initialSelectedLoan = evaluatedLoanProducts.find(
      (itm) => itm.name === loanName
    )
    setSelectedLoan({
      guid: initialSelectedLoan?.loanProductId,
      name: initialSelectedLoan?.name,
      label: initialSelectedLoan?.name,
    })
  }, [loanName, evaluatedLoanProducts])

  const handleSubmit = () => {
    setLoading(true)
    chooseLoan(dispatch, loanApplicationId, selectedLoan.guid)
      .then(() => setModalOpen(false))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getAssociatedLoanProductsBasic(loanApplicationId).then((res) =>
      setEvaluatedLoanProducts(res)
    )
  }, [])

  return (
    <Dialog
      open={isOpen}
      title={'Change Loan Product'}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '400px',
          '& .MuiDialogTitle-root': {
            alignItems: 'center',
            fontSize: '1.125rem',
          },
        },
      }}
      disableEscapeKeyDown={true}
      onClose={onClose}
      actions={
        <>
          <Button
            onClick={onClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            disabled={loading}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={!selectedLoan}
            loading={loading}
            onClick={handleSubmit}
          >
            {translate('global.saveChanges')}
          </Button>
        </>
      }
      actionsProps={{
        sx: {
          paddingTop: '8px',
          width: '100%',
          justifyContent: 'space-between',
          '& button': {
            width: '50%',
          },
        },
      }}
    >
      <Autocomplete
        options={evaluatedLoanProducts?.map((el) => ({
          guid: el?.loanProductId,
          name: el?.name,
          label: el?.name,
        }))}
        value={selectedLoan}
        onChange={(e, selected) => {
          setSelectedLoan(selected)
        }}
        sx={{ flexGrow: 1 }}
      />
    </Dialog>
  )
}

ChangeLoanProductModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  loanName: PropTypes.string.isRequired,
  setModalOpen: PropTypes.func.isRequired,
}

export default ChangeLoanProductModal
