import Api from 'easy-fetch-api'
import { formatDate } from 'common/date'
import { downloadFileBase64 } from 'common/utils'
import { STATE_IDS } from 'modules/loan-application/constants'
import { showNotification, setLoading } from 'modules/global/actions'
import { AF_PAGE_SIZE, APPROVAL_STATUS } from './utils/constants'

export const ACTIONS = {
  SET_DAILY_FUNDING_APPS: 'af.setDailyFunding',
  SET_DAILY_FUNDING_PENDING_APPS: 'af.setDailyProcessedApps',
  SET_DAILY_FUNDING_UNPROCESSED_APPS: 'af.setDailyUnProcessedApps',
  SET_FUNDED_APPS: 'af.setFundedApps',
  UPDATE_DAILY_FUNDING_APP: 'af.updateDailyFundingApp',
}

/** Daily Funding */
export const listDailyFundingApps = (dispatch, query) => {
  const itemsPerPage = query.itemsPerPage || AF_PAGE_SIZE
  return Api.get({
    url: `/FundingAutomation/list-daily-funding-loan-apps`,
    query: {
      itemsPerPage,
      ...query,
    },
  })
    .then((result) =>
      dispatch({
        type: ACTIONS.SET_DAILY_FUNDING_APPS,
        list: result.dailyFundingLoanApplications,
        totalPages: Math.ceil(result.itemCount / itemsPerPage),
      })
    )
    .catch(console.error)
}

export const listDailyProcessedApps = (
  dispatch,
  approved,
  query = {},
  isInitialPageLoad = false
) => {
  const itemsPerPage = query.itemsPerPage || AF_PAGE_SIZE
  const type = approved
    ? ACTIONS.SET_DAILY_FUNDING_PENDING_APPS
    : ACTIONS.SET_DAILY_FUNDING_UNPROCESSED_APPS

  return Api.get({
    url: `/FundingAutomation/list-daily-funding-loan-apps-post-process`,
    query: {
      itemsPerPage,
      ...query,
    },
  })
    .then((res) => {
      if (res && res.dailyFundingLoanApplications) {
        dispatch({
          type,
          list: res.dailyFundingLoanApplications,
          totalPages: Math.ceil(res.itemCount / itemsPerPage),
          isInitialPageLoad,
        })
      }
    })
    .catch(console.error)
}

export const listFundedApps = (dispatch, query = {}) => {
  const itemsPerPage = query.itemsPerPage || AF_PAGE_SIZE
  return Api.get({
    url: `/FundingAutomation/list-daily-funding-loan-apps-post-process`,
    query: {
      itemsPerPage,
      ...query,
    },
  }).then((res) => {
    res?.dailyFundingLoanApplications &&
      dispatch({
        type: ACTIONS.SET_FUNDED_APPS,
        list: res.dailyFundingLoanApplications,
        totalPages: Math.ceil(res.itemCount / itemsPerPage),
      })
  })
}

export const getLoanAppsForAddToDaily = (filters, page, pageSize) =>
  Api.get({
    url: '/FundingAutomation/list-loan-apps-for-daily-funding',
    query: {
      itemsPerPage: pageSize,
      pageNumber: page,
      LoanApplicationStateId: STATE_IDS.NTPFundsGranted,
      ...filters,
    },
  }).catch(console.error)

export const addToDaily = (loanAppIds) =>
  Api.post({
    url: `/FundingAutomation/add-daily-funding-loan-app`,
    data: loanAppIds,
  })

export const processDailyLoanApps = (loanAppIds) =>
  Api.put({
    url: `/FundingAutomation/process-daily-funding-loan-applications`,
    data: { dailyFundingLoanApplicationIds: loanAppIds },
  })

export const updateDaily = (dispatch, data) => {
  setLoading(dispatch, true)
  return Api.put({
    url: `/FundingAutomation/update-daily-funding-loan-app`,
    data,
  })
    .then((data) => {
      _successMessage(dispatch)(data)
      dispatch({ type: ACTIONS.UPDATE_DAILY_FUNDING_APP, data })
    })
    .finally(() => setLoading(dispatch, false))
}

/** Lender Settings */
export const listFundingTypes = () =>
  Api.get({ url: '/FundingAutomation/list-funding-types' }).catch(console.error)

export const listLenderSettings = (query) =>
  Api.get({
    url: '/FundingAutomation/list-lender-funding-settings',
    query: { itemsPerPage: AF_PAGE_SIZE, ...query },
  }).catch(console.error)

export const updateLenderSettings = (dispatch, data) =>
  Api.put({
    url: '/FundingAutomation/update-lender-funding-settings',
    data,
  })
    .then(_successMessage(dispatch))
    .catch(console.error)

export const listLenderFees = (query) =>
  Api.get({
    url: '/FundingAutomation/list-lender-funding-fees',
    query: { itemsPerPage: AF_PAGE_SIZE, ...query },
  }).catch(console.error)

export const listRevFees = (lenderIds = []) =>
  Api.post({
    url: '/FundingAutomation/list-reversal-fees',
    data: lenderIds,
  }).catch(console.error)

export const listLateFees = (lenderIds = []) =>
  Api.post({
    url: '/FundingAutomation/list-late-fees',
    data: lenderIds,
  }).catch(console.error)

export const updateLenderFees = (dispatch, data) =>
  Api.put({
    url: '/FundingAutomation/update-lender-funding-fees',
    data,
  })
    .then(_successMessage(dispatch))
    .catch(console.error)

export const updateRevFees = (organizationId, reversalFees, isStandalone) =>
  Api.post({
    url: '/FundingAutomation/edit-reversal-fees',
    data: { organizationId, reversalFees, isStandalone },
  }).catch(console.error)

export const updateLateFees = (organizationId, lateFees, isStandalone) =>
  Api.post({
    url: '/FundingAutomation/edit-late-fees',
    data: { organizationId, lateFees, isStandalone },
  }).catch(console.error)

/** Dealer Settings */
export const listDealerSettings = (query = {}) =>
  Api.get({
    url: '/FundingAutomation/list-dealer-funding-settings',
    query: { itemsPerPage: AF_PAGE_SIZE, ...query },
  }).catch(console.error)

export const updateDealerSettings = (dispatch, data) =>
  Api.put({
    url: '/FundingAutomation/update-dealer-funding-settings',
    data,
  })
    .then(_successMessage(dispatch))
    .catch(console.error)

/** Funding History */
export const listFundingHistory = (query = {}) =>
  Api.get({
    url: '/FundingAutomation/list-for-daily-funding-history',
    query: {
      itemsPerPage: AF_PAGE_SIZE,
      ...query,
      fundingDate: formatDate(query?.fundingDate),
    },
  }).catch(console.error)

/** Wires */
export const listWires = (query = {}) =>
  Api.get({
    url: '/FundingAutomation/list-wires',
    query: { itemsPerPage: AF_PAGE_SIZE, ...query },
  })
    .then((res) => {
      if (res.wires) {
        res.wires = res.wires.map((wire) => {
          wire.dailyFundingLoanApplications =
            wire.dailyFundingLoanApplications.map((app) => ({
              ...app,
              isCheckedByDefault: !app.isFunded,
            }))
          return wire
        })
      }
      return res
    })
    .catch(console.error)

export const downloadNachaFile = (wireId) =>
  Api.post({
    url: '/FundingAutomation/download-nacha-file',
    data: { wireId },
  })
    .then(downloadFileBase64)
    .catch(console.error)

export const uploadNachaFile = (wireId, file, dispatch) =>
  Api.put({
    url: '/FundingAutomation/custom-upload-nacha',
    data: {
      wireId,
      content: file.content,
      name: file.name,
      mimeType: file.mimeType,
    },
  })
    .then((res) => {
      showNotification(dispatch, { title: 'File uploaded successfully' })
      return res
    })
    .catch((err) => {
      console.error(err)
      return false
    })

export const regenerateWire = (wireId, dispatch) =>
  Api.post({
    url: '/FundingAutomation/re-generate-nacha',
    data: { wireId },
  })
    .then(() =>
      showNotification(dispatch, { title: 'Wires regenerated successfully' })
    )
    .catch(console.error)

/** Approval */
export const listApprovalFundingApps = (dispatch, query = {}) =>
  listDailyProcessedApps(dispatch, true, query)

export const downloadDocuments = () =>
  Api.get({ url: '/FundingAutomation/get-funding-excel-file' })
    .catch(console.error)
    .then(downloadFileBase64)

export const processApproval = (dailyFundingLoanApplicationIds) =>
  Api.put({
    url: '/FundingAutomation/approve-daily-funding-loan-applications',
    data: { dailyFundingLoanApplicationIds },
  })

export const declineApps = (loanApplicationIds, reason) =>
  Api.put({
    url: '/FundingAutomation/set-multiple-approval-statuses',
    data: loanApplicationIds.map((id) => ({
      dailyFundigLoanApplicationId: id,
      reason,
      approvalStatusId: APPROVAL_STATUS.DECLINED,
    })),
  })

export const removeOverride = (loanApplicationId) =>
  Api.put({
    url: '/FundingAutomation/set-approval-status',
    data: {
      dailyFundigLoanApplicationId: loanApplicationId,
      approvalStatusId: APPROVAL_STATUS.APPROVED,
    },
  })

export const regenerateDailyFunding = () =>
  Api.post({
    url: `/FundingAutomation/re-generate-daily-funding`,
    data: {
      effectiveDate: formatDate(new Date()),
    },
  })

export const exportDailyFundingAFP = () =>
  Api.get({
    url: '/FundingAutomation/download-daily-funding-excel',
  })
    .then(downloadFileBase64)
    .catch(console.error)

const _successMessage = (dispatch) => (result) => {
  if (result) {
    showNotification(dispatch, {
      title: 'Item was successfully updated',
      timeout: 3000,
    })
  }
  return result
}
