import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import LoanApplicationIcons from 'components/loan-app-icons'
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-narrow-right.svg'

import StatusCard from './card-status'
import { STATE_IDS, STATES_ORDER, STATES_ORDER_COMMERCIAL } from '../constants'

import { styles } from './style'
import { useMediaQuery } from '../../../common/hooks'
import {
  LARGE_MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../../../common/constants'

const Status = ({
  userData,
  achStatus,
  applicantsData,
  loanData,
  achOptInClicked,
  statuses,
  reasons,
  currentState,
  isCommercialApp,
  setIsEcgModalOpen,
  children,
}) => {
  const { t: translate, i18n } = useTranslation()
  const statesOrder = isCommercialApp ? STATES_ORDER_COMMERCIAL : STATES_ORDER
  const isTabletView = useMediaQuery(`(max-width:${TABLET_BREAKPOINT}px)`)
  const isMobileView = useMediaQuery(`(max-width:${LARGE_MOBILE_BREAKPOINT}px)`)

  const canOptInAch = useMemo(
    () =>
      hasAccess(userData, CLAIMS.CAN_OPT_IN_ACH) &&
      statesOrder[currentState.name] >= statesOrder.LoanSelected &&
      currentState.id !== STATE_IDS.ApplicationExpired,
    [userData, currentState]
  )
  const canChangeStatus = useMemo(
    () => hasAccess(userData, CLAIMS.CAN_EDIT_LOAN_APPLICATION_STATUS),
    [userData]
  )

  const getStatusActionDescription = () => {
    const textKey = `loanApplication.statusCard.loanApplicationState.residentialActionDescription.${loanData?.loanApplicationState?.id}`

    if (!isCommercialApp) {
      return translate(textKey)
    }

    const commercialTextKey = `loanApplication.statusCard.loanApplicationState.commercialActionDescription.${loanData?.loanApplicationState?.id}`

    return i18n.exists(commercialTextKey)
      ? translate(commercialTextKey)
      : translate(textKey)
  }

  const loanAppIconsData = {
    ...loanData.loanApplicationIconData,
    agingTier: loanData.loanApplicationIconData.agingTier?.id,
  }

  return (
    <div
      id="status-bar"
      style={{
        ...styles.statusBar,
        ...(isTabletView && { padding: '24px 24px 0' }),
        ...(isMobileView && { padding: '24px 16px 0' }),
      }}
    >
      <div style={styles.currentNextStep}>
        <div>
          <div style={styles.currentNextHeader}>
            {translate(
              'loanApplication.statusCard.loanApplicationState.current'
            )}
          </div>
          <div style={styles.currentText}>
            {translate(
              `loanApplication.statusCard.loanApplicationState.name.${loanData?.loanApplicationState?.id}`
            )}
          </div>
        </div>
        <div style={styles.arrow}>
          <ArrowRightIcon />
        </div>
        <div>
          <div style={styles.currentNextHeader}>
            {translate(
              'loanApplication.statusCard.loanApplicationState.upcoming'
            )}
          </div>
          <div style={styles.upcomingText}>{getStatusActionDescription()}</div>
        </div>

        <LoanApplicationIcons
          {...loanAppIconsData}
          style={{ marginLeft: 'auto' }}
        />
      </div>

      <div className="status__cards">
        <StatusCard
          isBorrower={!isCommercialApp}
          isOwners={isCommercialApp}
          applicantsData={applicantsData}
          className="status__card-right"
          loanData={loanData}
          statuses={statuses}
          reasons={reasons}
          canChangeStatus={canChangeStatus}
          currentState={currentState.name}
          setIsEcgModalOpen={setIsEcgModalOpen}
          isCommercialApp={isCommercialApp}
          achStatus={achStatus}
          onAchOptInClick={canOptInAch ? achOptInClicked : null}
        />
      </div>

      {children}
    </div>
  )
}

Status.propTypes = {
  userData: PropTypes.object.isRequired,
  achStatus: PropTypes.number,
  applicantsData: PropTypes.array.isRequired,
  loanData: PropTypes.object.isRequired,
  achOptInClicked: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  reasons: PropTypes.array.isRequired,
  currentState: PropTypes.object.isRequired,
  isCommercialApp: PropTypes.bool,
  setIsEcgModalOpen: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default Status
