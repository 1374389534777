import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'

import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { downloadFileBase64 } from 'common/utils'
import { CLAIMS } from 'common/claims'
import { hasAccess } from 'common/access'
import FileUploadV2 from 'components/file-upload-v2'
import {
  ATTACHMENT_STATUS_IDS,
  STATE_IDS,
} from 'modules/loan-application/constants'

import {
  downloadSingleAttachment,
  deleteInspectionAttachment,
  setAttachmentStatus,
} from '../actions'
import { UploadedStipulation } from '../ntp/uploaded-stipulation'

import { styles } from '../ntp/style'
import '../ntp/style.scss'

const { Paper, Dialog } = components

/**
 * Generic Document Component for rendering dynamic Stipulation docs
 *
 * Similar to the one used on Initial/Final NTP stages but less complex
 * Used on the Final Inspection & PTO Submission steps
 */
const InspectionDocumentsComponent = ({
  documents,
  loanApplicationId,
  loading,
  setLoading,
  reFetchDocuments,
  onUpload,
  userData,
  updateUserNtpStatus,
  loanApplicationStateId,
  isApproved,
  viewOnlyStates,
  uploadFunctionSettings,
  attachmentIdField,
  canUploadAttachments,
  canDeleteAttachments,
  canDeleteAttachmentsAfterSubmit,
  canSeeDecisioningButtons,
}) => {
  const { t: translate } = useTranslation()
  const [attachmentToDelete, setAttachmentToDelete] = useState(null)
  const {
    dispatch,
    state: {
      flow: { possibleEditStates, possibleViewOnlyStates },
    },
  } = useStore()
  const isEcg = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)

  /** On status change */
  const handleStatusChange = (attachmentId, statusId) => {
    setLoading(true)
    setAttachmentStatus(attachmentId, { statusId })
      .then(updateUserNtpStatus)
      .finally(() => setLoading(false))
  }

  /** On Download */
  const handleDownloadAttachment = (attachmentId) => {
    downloadSingleAttachment(dispatch, attachmentId).then(
      (res) => res && downloadFileBase64(res)
    )
  }

  /** On Delete */
  const handleOnClickDelete = () => {
    const attachmentId = attachmentToDelete[attachmentIdField]
    setAttachmentToDelete(null)
    setLoading(true)
    deleteInspectionAttachment(dispatch, loanApplicationId, attachmentId)
      .then(reFetchDocuments)
      .finally(() => setLoading(false))
  }

  const viewOnly = useMemo(() => {
    return (
      loanApplicationStateId?.id === STATE_IDS.ApplicationExpired ||
      (possibleViewOnlyStates.some((s) => viewOnlyStates.includes(s)) &&
        !possibleEditStates.some((s) => viewOnlyStates.includes(s)))
    )
  }, [
    viewOnlyStates,
    possibleEditStates,
    possibleViewOnlyStates,
    loanApplicationStateId,
  ])

  const list = documents.map((document, index) => {
    return (
      <Paper
        className="paper paper--no-shadow-box"
        sx={styles.documentCard}
        key={document.id}
      >
        <div>
          <FileUploadV2
            key={document.id}
            onChange={(file) => {
              setLoading(true)
              onUpload(file, document.id)
                .then(reFetchDocuments)
                .finally(() => setLoading(false))
            }}
            shouldDisplayScanButton={false}
            canUpload={canUploadAttachments}
            title={
              <>
                {document.name}
                {document.applicantName && ` - ${document.applicantName}`}
              </>
            }
            uploadFunctionSettings={uploadFunctionSettings(document.id)}
          />
        </div>
        <div className="ntp__documents-wrapper">
          {document.attachments?.map((attachment) => (
            <div key={attachment[attachmentIdField]}>
              <UploadedStipulation
                ntp={attachment}
                idField={attachmentIdField}
                stipulationIndex={index}
                setUserNTPToDelete={setAttachmentToDelete}
                canDeleteNTPsAfterApproval={canDeleteAttachmentsAfterSubmit}
                canSeeDecisioningButtons={
                  canSeeDecisioningButtons && (!isApproved || isEcg)
                }
                canDeleteNTP={canDeleteAttachments}
                ntpApproved={isApproved}
                onNeedsReview={() => {
                  handleStatusChange(
                    attachment[attachmentIdField],
                    ATTACHMENT_STATUS_IDS.NEEDS_REVIEW
                  )
                }}
                onApprove={() => {
                  handleStatusChange(
                    attachment[attachmentIdField],
                    ATTACHMENT_STATUS_IDS.APPROVED
                  )
                }}
                onReject={() => {
                  handleStatusChange(
                    attachment[attachmentIdField],
                    ATTACHMENT_STATUS_IDS.DECLINED
                  )
                }}
                onDownloadAttachment={handleDownloadAttachment}
                viewOnly={viewOnly}
              />
            </div>
          ))}
        </div>
      </Paper>
    )
  })

  return (
    <div className={cx('ntp__documents', { disabled: loading })}>
      <Dialog
        open={!!attachmentToDelete}
        title={translate(
          'loanApplication.ntpStepsCommon.removeFileConfirmation'
        )}
        onClose={() => setAttachmentToDelete(null)}
        actions={
          <>
            <Button
              onClick={() => setAttachmentToDelete(null)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button onClick={handleOnClickDelete}>
              {translate('buttons.yes')}
            </Button>
          </>
        }
      />

      {list}
    </div>
  )
}

InspectionDocumentsComponent.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      attachments: PropTypes.array.isRequired,
    })
  ).isRequired,
  commercialNTP: PropTypes.bool,
  loanApplicationId: PropTypes.string.isRequired,
  estimatedCombinedIncomeToBeProven: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  reFetchDocuments: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  updateUserNtpStatus: PropTypes.func,
  loanApplicationStateId: PropTypes.number.isRequired,
  isApproved: PropTypes.bool,
  viewOnlyStates: PropTypes.array.isRequired,
  uploadFunctionSettings: PropTypes.func.isRequired,
  attachmentIdField: PropTypes.string.isRequired,
  canUploadAttachments: PropTypes.bool.isRequired,
  canDeleteAttachments: PropTypes.bool.isRequired,
  canDeleteAttachmentsAfterSubmit: PropTypes.bool.isRequired,
  canSeeDecisioningButtons: PropTypes.bool.isRequired,
}

export default InspectionDocumentsComponent
