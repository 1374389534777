import Api from 'easy-fetch-api'
import moment from 'moment'

import {
  APPLICANT_TYPE,
  dateFormatServer,
  dateFormatServerWithTime,
  dobDateFormat,
} from 'common/constants'
import { isSpecialCaseBank } from 'common/utils'
import { convertDateToClient } from 'common/date'
import { convertToFloat, sumFloat } from 'common/number'
import { isEmpty } from 'components/validator'
import {
  initializePrefillData,
  setLoading as setGlobalLoading,
  showNotification,
} from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { DEFAULT_LOAN_FORM_DATA } from './constants'

/** Format Loan Form from server to client format -> used for edit mode */
export const formDataToClientFormat = (serverFormData) => {
  const {
    applicants,
    loanApplicationId,
    salesOrganizationId,
    salesOrganizationName,
    salesRepresentativeId,
    salesRepresentativeName,
    loanAmount,
    serviceAddress,
    propertyTitleReportId,
    selectedLoanSignatures,
    solarData,
    incomeData,
    isOnHold,
    loanAmountDecreased,
    loanAmountIncreased,
    cancelled,
    creditExpired,
    hasReversal,
    agingTier,
    initialFundingAmount,
    initialFundingGrantedDate,
    initialNetFundingAmount,
    finalNetFundingAmount,
    finalFundingAmount,
    finalFundingGrantedDate,
    finalGrossFundingAmount,
    amountPaidToThirdParty,
    dateOfAmountPaidToThirdParty,
    useInitialFundingThirdParty,
    fundingReversalReason,
    fundingReversalRequestDate,
    fundingReversalDate,
    loanApplicationStatusReason,
    loanApplicationStatusReasonNote,
    isDuplicateAddress,
    isDuplicateSsn,
    netAmount,
    downPayment,
    batteryOnly,
    bypassAddressValidation,
    canExtendExpirationPeriod,
    canExpire,
    loanApplicationNumber,
    isAddressOverridenFromPropertyTitle,
    estimatedCombinedIncomeToBeProven,
    isAdverseActionNoticeEmailSent,
    ptoId,
    previousLoanApplicationStateId,
    loanPaymentDueDate,
    dateCreated,
    isPtoNotMandatory,
    finalInspectionFundingAmount,
  } = serverFormData
  const borrower = _formatApplicantForClient(
    applicants?.find((el) => el.applicantTypeId === APPLICANT_TYPE.BORROWER)
  )
  const coBorrower = _formatApplicantForClient(
    applicants?.find((el) => el.applicantTypeId === APPLICANT_TYPE.COBORROWER)
  )

  const mapped = {
    loanApplicationId,
    salesOrganizationId,
    salesOrganizationName,
    salesRepresentativeId,
    salesRepresentativeName,
    serviceAddress,
    selectedLoanSignatures: mapLoanProductsForClient(selectedLoanSignatures),
    borrowerGeneralDetails: {
      ...borrower,
      loanAmount,
      hasCoBorrower: !isEmpty(coBorrower),
    },
    solarData,
    incomeData,
    propertyTitleReportId,
    isOnHold,
    loanAmountDecreased,
    loanAmountIncreased,
    cancelled,
    creditExpired,
    hasReversal,
    agingTier,
    initialFundingAmount,
    initialFundingGrantedDate,
    initialNetFundingAmount,
    finalNetFundingAmount,
    finalFundingAmount,
    finalFundingGrantedDate,
    finalGrossFundingAmount,
    amountPaidToThirdParty,
    dateOfAmountPaidToThirdParty,
    useInitialFundingThirdParty,
    fundingReversalReason,
    fundingReversalRequestDate,
    fundingReversalDate,
    loanApplicationStatusReason,
    loanApplicationStatusReasonNote,
    isDuplicateAddress,
    isDuplicateSsn,
    netAmount,
    downPayment,
    batteryOnly,
    bypassAddressValidation,
    canExtendExpirationPeriod,
    canExpire,
    loanApplicationNumber,
    isAddressOverridenFromPropertyTitle,
    estimatedCombinedIncomeToBeProven,
    isAdverseActionNoticesDelivered: isAdverseActionNoticeEmailSent,
    ptoId,
    previousLoanApplicationStateId,
    loanPaymentDueDate,
    dateCreated,
    isPtoNotMandatory,
    finalInspectionFundingAmount,
  }
  if (!isEmpty(coBorrower)) {
    mapped.coBorrowerGeneralDetails = { ...coBorrower }
  }

  return mapped
}

export const formDataToClientFormatCommercial = (serverFormData) => {
  const { principals, ...data } = serverFormData

  return {
    ...data,
    businessOwners: [...principals],
    selectedLoanSignatures: mapLoanProductsForClient(
      data.selectedLoanSignatures
    ),
  }
}

/** Format Loan Form from client to server format -> used for Submitting Loan App */
export const formDataToServerFormat = ({
  borrowerGeneralDetails,
  addressDetails,
  coBorrowerGeneralDetails,
  coBorrowerAddressDetails,
  allLoanSignatures,
  selectedLoanSignatures,
  serviceAddress,
  isEdit,
  solarData,
  incomeData,
  borrowerIdentification,
  coBorrowerIdentification,
  disclosureStatuses,
}) => {
  const applicants = [
    formatApplicantForServer(
      borrowerGeneralDetails,
      addressDetails,
      borrowerIdentification,
      APPLICANT_TYPE.BORROWER,
      disclosureStatuses?.[0]
    ),
  ]
  if (borrowerGeneralDetails.hasCoBorrower) {
    applicants.push(
      formatApplicantForServer(
        coBorrowerGeneralDetails,
        coBorrowerAddressDetails,
        coBorrowerIdentification,
        APPLICANT_TYPE.COBORROWER,
        disclosureStatuses?.[1]
      )
    )
  }
  return {
    loanAmount: borrowerGeneralDetails.loanAmount,
    selectedLoanSignatures: allLoanSignatures.map((el) => ({
      selectedLoanSignatureId: el.guid,
      loanProductSignature: el.value,
      isSelected: !!selectedLoanSignatures.find(
        (selected) => selected.guid === el.guid
      ),
    })),
    serviceAddress,
    applicants,
    ...(isEdit && { solarData, incomeData }),
  }
}

/** Map a list of loan products from the server format to the client one */
export const mapLoanProductsForClient = (loanProducts) => {
  if (!loanProducts) {
    return []
  }

  return loanProducts.map((el) => ({
    guid: el.selectedLoanSignatureId,
    value: el.loanProductSignature,
    isSelected: el.isSelected,
  }))
}

/** Map the ACH Form data from the server format to the client one */
export const achDataToClientFormat = (achData) => {
  return {
    ...achData,
    accountTypeId: achData.accountType?.id,
  }
}

export const formatIntToCurrency = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
  }).format(value)
}

export const formatToPercentage = (value) => {
  return (convertToFloat(value) * 100).toFixed(2)
}

/** Extract Applicant info and format for the BE */
export function formatApplicantForServer(
  applicant,
  addressDetails,
  identification,
  applicantTypeId = APPLICANT_TYPE.BORROWER,
  disclosureStatuses = {}
) {
  const {
    sameWithServiceAddress,
    hasAdditionalIncome,
    additionalIncomeTypeId,
    additionalIncomeAmount,
    jobTitle,
    yearlyIncome,
    employmentStatusId,
    residencyOccupationTypeId,
    employer,
    monthsEmployed,
    employmentStatus,
    dateOfBirth,
    ...rest
  } = applicant

  const hasIdentificationData = Object.values(identification).some(
    (val) => !isEmpty(val)
  )

  return {
    ...rest,
    sameWithServiceAddress,
    billingAddress: sameWithServiceAddress ? undefined : { ...addressDetails },
    residencyOccupationTypeId,
    dateOfBirth: moment(dateOfBirth, dobDateFormat).format(dateFormatServer),
    employmentStatus: {
      ...(hasAdditionalIncome && {
        additionalIncomeTypeId,
        additionalIncomeAmount,
      }),
      employmentStatusTypeId: employmentStatus,
      employer,
      employmentStatusId,
      jobTitle,
      yearlyIncome,
      monthsEmployed,
    },
    ...disclosureStatuses,
    applicantTypeId,
    identificationData: hasIdentificationData
      ? {
          ...identification,
          applicantId: applicant.applicantId,
          name: `${applicant.firstName} ${applicant.lastName}`,
        }
      : undefined,
  }
}

/** Format Applicant info from the server format to the client one */
function _formatApplicantForClient(applicant) {
  if (!applicant) {
    return {}
  }
  const {
    employmentStatus,
    employmentStatusData,
    identificationData,
    dateOfBirth,
    ...restOfApplicant
  } = applicant

  const {
    employer,
    jobTitle,
    yearlyIncome,
    employmentStatusTypeId,
    additionalIncomeTypeId,
    employmentStatusId,
    additionalIncomeAmount,
    monthsEmployed,
    hasAdditionalIncome,
  } = employmentStatus

  return {
    ...restOfApplicant,
    dateOfBirth: moment(dateOfBirth, dateFormatServerWithTime).format(
      dobDateFormat
    ),
    employmentStatus: employmentStatusTypeId,
    ...((additionalIncomeTypeId || additionalIncomeTypeId === 0) && {
      hasAdditionalIncome,
      additionalIncomeTypeId,
      additionalIncomeAmount,
    }),
    identificationData,
    monthsEmployed,
    employer,
    jobTitle,
    yearlyIncome,
    employmentStatusId,
  }
}

/** Formats the NTP response from the server to client format - dealing with formatting dates */
export const formatNtpsToClient = (res) => {
  return {
    ...res,
    applicationNTPs: res.applicationNTPs.map((appNtp) => ({
      ...appNtp,
      userNTPs: appNtp.userNTPs.map((ntp) => ({
        ...ntp,
        attachment: {
          ...ntp.attachment,
          dateCreated: convertDateToClient(ntp.attachment.dateCreated),
        },
      })),
    })),
    commentsSection: res.commentsSection?.map((comment) => ({
      ...comment,
      dateCreated: convertDateToClient(comment.dateCreated),
    })),
  }
}

export const formatTimeForHistoricalEntry = (entry) => ({
  ...entry,
  dateAndTimeOfAction: convertDateToClient(entry.dateAndTimeOfAction),
})

export const formatAddressToAddressValidationAPI = (address) => {
  return {
    address: {
      regionCode: address?.country || '',
      addressLines: [
        `${address?.addressFirstLine || ''}  ${address?.city || ''} ${
          address?.state || ''
        } ${address?.county || ''} ${address?.zipCode || ''}`,
      ],
    },
  }
}

export const validateAddress = async (address) => {
  const url = process.env.REACT_APP_ADDRESS_VALIDATION_URL

  try {
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(formatAddressToAddressValidationAPI(address)),
    })
    const response = await res.json()

    const errors = []
    response?.result?.address?.addressComponents?.forEach((component) => {
      if (component.confirmationLevel !== 'CONFIRMED') {
        if (
          component.componentType === 'postal_code' ||
          component.componentType === 'administrative_area_level_1'
        ) {
          // for now, be less strict with the validation and check for validity only for the postal code and state
          errors.push(component.componentType)
        }
      }
    })

    return errors
  } catch (e) {
    console.error(e)
    throw e
  }
}

const getParamValueFromOptions = (query, options, value) => {
  return (
    query.get(value) && options.find((o) => o.value === query.get(value))?.id
  )
}

export const createLoanFormDataFromQueryParams = (
  query,
  citizenships,
  militaryAffiliation,
  employmentStatuses,
  residencyOccupation,
  communicationMethods,
  additionalIncomeTypes
) => {
  const loanFormData = { ...DEFAULT_LOAN_FORM_DATA }

  // Mapping borrower dropdown values
  const citizenshipTypeId = getParamValueFromOptions(
    query,
    citizenships,
    'citizenshipType'
  )
  const militaryAffiliationId = getParamValueFromOptions(
    query,
    militaryAffiliation,
    'militaryAffiliation'
  )
  const preferredCommunicationMethodId = getParamValueFromOptions(
    query,
    communicationMethods,
    'preferredCommunicationMethod'
  )
  const residencyOccupationTypeId = getParamValueFromOptions(
    query,
    residencyOccupation,
    'residencyOccupationType'
  )
  const employmentStatus = getParamValueFromOptions(
    query,
    employmentStatuses,
    'employmentStatus'
  )
  const additionalIncomeType = getParamValueFromOptions(
    query,
    additionalIncomeTypes,
    'additionalIncomeType'
  )

  // Mapping co-borrower dropdown values
  const cbCitizenshipTypeId = getParamValueFromOptions(
    query,
    citizenships,
    'cbCitizenshipType'
  )
  const cbMilitaryAffiliationId = getParamValueFromOptions(
    query,
    militaryAffiliation,
    'cbMilitaryAffiliation'
  )
  const cbPreferredCommunicationMethodId = getParamValueFromOptions(
    query,
    communicationMethods,
    'cbPreferredCommunicationMethod'
  )
  const cbResidencyOccupationTypeId = getParamValueFromOptions(
    query,
    residencyOccupation,
    'cbResidencyOccupationType'
  )
  const cbEmploymentStatus = getParamValueFromOptions(
    query,
    employmentStatuses,
    'cbEmploymentStatus'
  )
  const cbAdditionalIncomeType = getParamValueFromOptions(
    query,
    additionalIncomeTypes,
    'cbAdditionalIncomeType'
  )

  const dob = query.get('dateOfBirth')
  const cbDob = query.get('cbDateOfBirth')

  loanFormData.bypassAddressValidation =
    query.get('bypassAddressValidation')?.toLowerCase() === 'true' || false

  loanFormData.borrowerGeneralDetails = {
    // General
    firstName: query.get('firstName') || '',
    lastName: query.get('lastName') || '',
    last4SSN: query.get('last4SSN') || '',
    phoneNumber: query.get('phoneNumber') || '',
    emailAddress: query.get('emailAddress') || '',
    dateOfBirth: (moment(dob).isValid() && moment(dob).toDate()) || null,
    sameWithServiceAddress: !(
      query.get('sameWithServiceAddress')?.toLowerCase() === 'false'
    ),
    militaryAffiliationId: militaryAffiliationId,
    preferredCommunicationMethodId: preferredCommunicationMethodId,
    citizenshipTypeId: citizenshipTypeId,
    residencyOccupationTypeId: residencyOccupationTypeId,
    monthsEmployed: query.get('monthsEmployed') || '',
    employer: query.get('employer') || '',
    jobTitle: query.get('jobTitle') || '',
    yearlyIncome: query.get('yearlyIncome') || '',
    employmentStatus: employmentStatus,
    loanAmount: query.get('loanAmount') || '',
    hasCoBorrower:
      query.get('hasCoBorrower')?.toLowerCase() === 'true' || false,
    // Additional income
    hasAdditionalIncome:
      query.get('hasAdditionalIncome')?.toLowerCase() === 'true' || false,
    additionalIncomeAmount: query.get('additionalIncomeAmount') || '',
    additionalIncomeTypeId: additionalIncomeType,
    // Billing address
    billingAddress: {
      addressFirstLine: query.get('addressFirstLine') || '',
      addressSecondLine: query.get('addressSecondLine') || '',
      city: query.get('city') || '',
      county: query.get('county') || '',
      state: query.get('state') || '',
      zipCode: query.get('zipCode') || '',
    },
  }

  loanFormData.coBorrowerGeneralDetails = {
    // General
    firstName: query.get('cbFirstName') || '',
    lastName: query.get('cbLastName') || '',
    last4SSN: query.get('cbLast4SSN') || '',
    phoneNumber: query.get('cbPhoneNumber') || '',
    emailAddress: query.get('cbEmailAddress') || '',
    dateOfBirth: (moment(cbDob).isValid() && moment(cbDob).toDate()) || null,
    sameWithServiceAddress: !(
      query.get('cbSameWithServiceAddress')?.toLowerCase() === 'false'
    ),
    militaryAffiliationId: cbMilitaryAffiliationId,
    preferredCommunicationMethodId: cbPreferredCommunicationMethodId,
    citizenshipTypeId: cbCitizenshipTypeId,
    residencyOccupationTypeId: cbResidencyOccupationTypeId,
    monthsEmployed: query.get('cbMonthsEmployed') || '',
    employer: query.get('cbEmployer') || '',
    jobTitle: query.get('cbJobTitle') || '',
    yearlyIncome: query.get('cbYearlyIncome') || '',
    employmentStatus: cbEmploymentStatus,
    loanAmount: query.get('cbLoanAmount') || 0,
    // Additional income
    hasAdditionalIncome:
      query.get('cbHasAdditionalIncome')?.toLowerCase() === 'true' || false,
    additionalIncomeAmount: query.get('cbAdditionalIncomeAmount') || '',
    additionalIncomeTypeId: cbAdditionalIncomeType,
    // Billing address
    billingAddress: {
      addressFirstLine: query.get('cbAddressFirstLine') || '',
      city: query.get('cbCity') || '',
      county: query.get('cbCounty') || '',
      state: query.get('cbState') || '',
      zipCode: query.get('cbZipCode') || '',
    },
  }

  loanFormData.serviceAddress = {
    addressFirstLine: query.get('saAddressFirstLine') || '',
    addressSecondLine: query.get('saAddressSecondLine') || '',
    city: query.get('saCity') || '',
    state: query.get('saState') || '',
    zipCode: query.get('saZipCode') || '',
    county: query.get('saCounty') || '',
  }

  return loanFormData
}

/**
 * Initializes the identity verification of the IDs via Stripe
 * The response comes via websocket from the server {@see 'modules/global/index.js' -> prefillUserDataFromStripe}
 */
export const initializeStripeIDVerification = async (
  dispatch,
  applicantId,
  applicantIndex
) => {
  try {
    setGlobalLoading(dispatch, true)
    initializePrefillData(dispatch)

    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY)

    // Create the VerificationSession on the server.
    const { clientSecret, sessionId } = await Api.post({
      url: '/LoanApplication/create-id-verification-session',
      data: {
        applicantId,
        applicantIndex,
      },
    }).catch(console.error)

    // Open the modal on the client.
    const { error } = await stripe.verifyIdentity(clientSecret)
    if (!error) {
      // If no error the global loading is set to false in the websocket listener under global/index.js
      return sessionId
    } else {
      setGlobalLoading(dispatch, false)
      if (error?.code !== 'session_cancelled') {
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: 'Error verifying your ID. Please try again.',
        })
        console.error(error.title)
      }
    }
  } catch (e) {
    console.error(e.message)
  }
}

export const getPrefillData = async (verificationSessionId) => {
  const response = await Api.get({
    url: '/LoanApplication/id-prefill-data',
    query: {
      verificationSessionId,
    },
  }).catch(console.error)

  return response || null
}

/** this will return data for both borrower and coborrower */
export const getIdVerificationByLoanApplication = async (loanApplicationId) => {
  const response = await Api.get({
    url: `/LoanApplication/${loanApplicationId}/id-verification-results`,
  }).catch(console.error)

  if (response) {
    return response
  }
}

export const calculateApr = (
  calculatedFinancingDetails,
  lenderId,
  interestRateFee = 0
) => {
  let apr
  if (isSpecialCaseBank(lenderId)) {
    const simplePayment = calculatedFinancingDetails?.paymentPeriods?.find(
      (period) => period.amortizationType.name === 'Simple'
    )
    const nonPromotionalPeriod = simplePayment?.paymentMonths?.find(
      (el) => el.isPromotionalPeriod === false
    )
    apr = sumFloat(nonPromotionalPeriod?.apr, interestRateFee)
  } else {
    apr = calculatedFinancingDetails?.averageAPR
  }
  return apr ? `${apr}` : '-'
}

export const calculateMonthlyPaymentNoPaydown = (
  paymentWithAch,
  noPaydown,
  lenderId
) => {
  if (isSpecialCaseBank(lenderId)) {
    return paymentWithAch ? `$${paymentWithAch}` : '-'
  } else {
    return noPaydown ? `$${noPaydown}` : '-'
  }
}

export const calculateRate = (
  calculatedFinancingDetails,
  lenderId,
  interestRateDiscount,
  interestRateFee = 0
) => {
  if (isSpecialCaseBank(lenderId)) {
    return calculateApr(calculatedFinancingDetails, lenderId, interestRateFee)
  } else if (!calculatedFinancingDetails?.interestRate) {
    return '-'
  } else if (interestRateDiscount) {
    return `${sumFloat(
      calculatedFinancingDetails.interestRate,
      -interestRateDiscount
    )}`
  } else {
    return `${sumFloat(
      calculatedFinancingDetails.interestRate,
      interestRateFee
    )}`
  }
}
