import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'
import { BUTTON_SIZES } from 'components/button'
import StatusComponent, { STATUSES } from 'components/status'
import moment from 'moment'
import { dateFormatUS } from 'common/constants'
import { AccessWrapper } from 'common/access'
import { CLAIMS } from 'common/claims'
import { ATTACHMENT_STATUS_IDS } from '../../constants'

import { styles } from './style.js'

const { Paper, Tooltip, IconButton, FeaturedIconColors } = components
const { CheckIcon, XCloseIcon, EyeIcon, TrashIcon02, DownloadIcon } = assets

export const UploadedStipulation = ({
  ntp,
  setUserNTPToDelete,
  stipulationIndex,
  ntpApproved,
  canDeleteNTP,
  canDeleteNTPsAfterApproval,
  canSeeDecisioningButtons,
  onReject,
  onNeedsReview,
  onApprove,
  onDownloadAttachment,
  viewOnly,
  idField = 'userNTPId',
}) => {
  const { t: translate } = useTranslation()

  const renderDeleteButton = (userNTP, index) => {
    const content = (
      <Tooltip title={translate('buttons.delete')}>
        <IconButton
          disabled={viewOnly}
          size={BUTTON_SIZES.SMALL}
          onClick={() =>
            setUserNTPToDelete({ [idField]: userNTP[idField], index })
          }
          sx={{ ml: 2 }}
        >
          <TrashIcon02 sx={{ marginBottom: '3px' }} />
        </IconButton>
      </Tooltip>
    )

    if (canDeleteNTPsAfterApproval || !ntpApproved) {
      if (userNTP.statusId === ATTACHMENT_STATUS_IDS.APPROVED) {
        return (
          <AccessWrapper claims={CLAIMS.CAN_DELETE_NTPS}>
            {content}
          </AccessWrapper>
        )
      } else {
        return content
      }
    }
  }
  const getInfoByNTP = (ntp) => {
    if (ntp.statusId === ATTACHMENT_STATUS_IDS.APPROVED) {
      return {
        status: STATUSES.ACTIVE,
        label: translate('global.approved'),
        color: FeaturedIconColors.SUCCESS,
      }
    }

    if (ntp.statusId === ATTACHMENT_STATUS_IDS.DECLINED) {
      return {
        status: STATUSES.INACTIVE,
        label: translate('global.declined'),
        color: FeaturedIconColors.ERROR,
      }
    }

    if (ntp.statusId === ATTACHMENT_STATUS_IDS.NEEDS_REVIEW) {
      return {
        status: STATUSES.ON_HOLD,
        label: translate('loanApplication.ntpStepsCommon.needsReview'),
        color: FeaturedIconColors.WARNING,
      }
    }

    return { status: null, label: '', color: FeaturedIconColors.PRIMARY }
  }

  const { status, label } = getInfoByNTP(ntp)
  const attachment = ntp.attachment || ntp

  return (
    <Paper style={styles.paper}>
      <div style={styles.wrapper}>
        <div style={styles.attachmentInfoWrapper}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DownloadIcon
              className="always-enabled"
              style={styles.attachmentIcon}
              onClick={() => onDownloadAttachment(attachment.attachmentId)}
            />
            <div style={styles.attachmentInfo}>
              <Tooltip title={attachment.name}>
                <div style={styles.attachmentName}>{attachment.name}</div>
              </Tooltip>
              <div style={styles.attachmentDate}>
                {moment(attachment.dateCreated).format(dateFormatUS)}
              </div>
            </div>
          </div>
          {status && <StatusComponent status={status} label={label} />}

          <div className="ntp-upload">
            {canSeeDecisioningButtons && (
              <>
                <Tooltip
                  title={translate(
                    'loanApplication.ntpStepsCommon.needsReview'
                  )}
                >
                  <IconButton
                    disabled={viewOnly}
                    size={BUTTON_SIZES.SMALL}
                    onClick={onNeedsReview}
                    sx={{ ml: 2 }}
                  >
                    <EyeIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={translate('loanApplication.ntpStepsCommon.reject')}
                >
                  <IconButton
                    disabled={viewOnly}
                    size={BUTTON_SIZES.SMALL}
                    onClick={onReject}
                    sx={{ ml: 2 }}
                  >
                    <XCloseIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title={translate('loanApplication.ntpStepsCommon.approve')}
                >
                  <IconButton
                    disabled={viewOnly}
                    size={BUTTON_SIZES.SMALL}
                    onClick={onApprove}
                    sx={{ ml: 2 }}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {canDeleteNTP && renderDeleteButton(ntp, stipulationIndex)}
          </div>
        </div>
      </div>
    </Paper>
  )
}

UploadedStipulation.propTypes = {
  ntp: PropTypes.object.isRequired,
  stipulationIndex: PropTypes.number.isRequired,
  setUserNTPToDelete: PropTypes.func.isRequired,
  ntpApproved: PropTypes.bool.isRequired,
  canDeleteNTPsAfterApproval: PropTypes.bool.isRequired,
  canDeleteNTP: PropTypes.bool.isRequired,
  onReject: PropTypes.func.isRequired,
  onNeedsReview: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onDownloadAttachment: PropTypes.func.isRequired,
  canSeeDecisioningButtons: PropTypes.bool,
  viewOnly: PropTypes.bool.isRequired,
  idField: PropTypes.string,
}
