import Cookie from 'js-cookie'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import { logout, refreshToken } from 'modules/session/actions'
import {
  COOKIE_NAME,
  EXCLUDED_CLAIMS_FOR_SUPER_ADMIN,
  REFRESH_COOKIE_NAME,
} from './constants'

/**
 * Checks access to a functionality
 * Checks through all requiredClaims to see if the user is allowed. If one is found, access is granted
 *
 * @param {Object} user - user object
 * @param {Array<{String}> | String} [requiredClaims] - Array of Claims
 * @returns {Boolean}
 */

export const hasAccess = (user, requiredClaims) => {
  if (!user) {
    return false
  }

  if (!requiredClaims) {
    return true
  }

  if (!Array.isArray(requiredClaims)) {
    requiredClaims = [requiredClaims]
  }

  if (user.isSuperAdmin) {
    if (
      requiredClaims.some((claim) =>
        EXCLUDED_CLAIMS_FOR_SUPER_ADMIN.includes(claim)
      )
    ) {
      return false
    }
    return true
  }

  return requiredClaims.some((claim) => user.claims?.[claim])
}

/**
 * Update the Access token via the Refresh Token if it expired
 * @param {Function} dispatch
 * @param {Function} setLoading
 * @param {Object} userData
 * @returns {Promise<null|String>} Promise that resolves with the Token or null
 */
export const updateAccessTokenIfExpired = async (
  dispatch,
  setLoading,
  userData
) => {
  const accessToken = Cookie.get(COOKIE_NAME)
  const refreshTokenValue = Cookie.get(REFRESH_COOKIE_NAME)

  if (!accessToken && !refreshTokenValue) {
    logout(dispatch)
    return null
  }

  if (!accessToken && refreshTokenValue) {
    setLoading(true)
    refreshToken(dispatch, userData)
      .then((resp) => {
        return resp.token
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return null
}

/**
 * React Wrapper for managing content that needs to display based on Claims.
 * If no claims are passed it defaults to allowing access
 * @param {Object} options
 * @param {Array<String> | String} options.claims - array of Claims or a single Claim string
 * @param {Node} options.children - content to be rendered
 * @constructor
 */
export const AccessWrapper = ({ claims, children }) => {
  const { state } = useStore()
  if (!hasAccess(state.session.userData, claims)) {
    return null
  }
  return <>{children}</>
}
AccessWrapper.propTypes = {
  claims: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node,
}
