import Api from 'easy-fetch-api'
import Cookie from 'js-cookie'
import { getPostAuthenticationRedirect } from 'components/history'
import { COOKIE_NAME, REFRESH_COOKIE_NAME } from 'common/constants'
import { sleep } from 'common/utils'
import { SPECIAL_ENDPOINTS } from 'api-middleware'
import { decodeJwtToken } from './jwt-token'

export const ACTIONS = {
  LOGIN: 'session.login',
  SET_USER_DATA: 'session.setUserData',
  LOGOUT: 'session.logout',
  REHYDRATE_USER_DATA: 'session.rehydrateUserData',
  UPDATE_NOTIFICATION_RECONNECT: 'session.reconnectNotification',
}

/**
 * Performs user authentication.
 * Retrieves user associations and selects the first one.
 * After login it stores the token and fetches user data
 * If all goes well it redirects the user the landing page
 */
export const login = (email, code, keepSignedIn, dispatch, navigate) =>
  Api.post({
    url: '/Identity/verify-2fa-code',
    data: { emailAddress: email, code },
  })
    .then(async (result) => {
      if (result?.token && result?.refreshToken) {
        // Perform login
        dispatch({ type: ACTIONS.LOGIN, data: result })
        await sleep(100)
        const associationsRes = await getAssociations(dispatch)
        await enrich(dispatch, associationsRes.associations[0])
        const redirect = getPostAuthenticationRedirect(
          associationsRes.associations[0].role.roleTypeId
        )
        navigate(redirect)
      }
      return result
    })
    .catch((e) => {
      console.error()
      return e
    })

export const authenticate = (email, password) =>
  Api.post({
    url: '/Identity/authenticate-user',
    data: { emailAddress: email, password },
  }).catch(console.error)

export const requestPhoneNumber = (emailAddress, password, newPhoneNumber) => {
  return Api.post({
    url: '/Users/request-phone-number-change',
    data: { emailAddress, password, newPhoneNumber: `+1${newPhoneNumber}` },
  })
}

export const changePhoneNumber = (
  emailAddress,
  changePhoneNumberCode,
  changePhoneNumberToken,
  newPhoneNumber,
  dispatch
) => {
  return Api.post({
    url: '/Users/change-phone-number',
    data: {
      emailAddress,
      changePhoneNumberCode,
      changePhoneNumberToken,
      newPhoneNumber: `+1${newPhoneNumber}`,
    },
  })
    .then(async (result) => {
      if (result?.token) {
        // Perform login
        dispatch({ type: ACTIONS.LOGIN, data: result })
        await sleep(100)
        const associationsRes = await getAssociations(dispatch)
        await enrich(dispatch, associationsRes.associations[0])
      }
      return result
    })
    .catch((e) => {
      console.error()
      return e
    })
}

/** Fetch user associations */
export const getAssociations = (dispatch) =>
  Api.get({ url: SPECIAL_ENDPOINTS.ME }).then((result) => {
    if (result?.associations) {
      dispatch({
        type: ACTIONS.SET_USER_DATA,
        data: {
          associations: result.associations,
          shouldRedirectRedesign: result.shouldRedirectRedesign,
        },
      })
    }
    return result
  })

/**
 * The actual method that performs the Refresh Token mechanism
 * It performs 2 calls - 1 for getting the Access Token + an Enrich call
 *
 * @param {Function} dispatch
 * @param {Object} [userData] optional - if provided, it will attempt to re-create the Enrich based on the user's current selected organization
 * @returns {Promise<Object>} result response from the Api call
 */
export const refreshToken = async (dispatch, userData) => {
  const refreshToken = Cookie.get(REFRESH_COOKIE_NAME)
  return Api.post({
    url: SPECIAL_ENDPOINTS.REFRESH_TOKEN,
    data: { refreshToken },
  }).then(async (result) => {
    if (result?.token && result?.refreshToken) {
      dispatch({ type: ACTIONS.LOGIN, data: result })
      await sleep(100)
      const associationsRes = await getAssociations(dispatch)
      const index = _findCurrentAssociationIndex(
        userData,
        associationsRes.associations
      )
      await enrich(dispatch, associationsRes.associations[index])

      return result
    }
  })
}

/**
 * Enrich the Access Token with claims, a selected org, user role + and other relevant info
 */
export const enrich = (dispatch, organization) =>
  Api.put({
    url: SPECIAL_ENDPOINTS.ENRICH,
    data: {
      associationId: organization.guid,
      encodedTokenString: Cookie.get(COOKIE_NAME),
    },
  })
    .then((res) => {
      if (res?.token) {
        const { token, expiration } = res
        const userData = decodeJwtToken(res.token)

        dispatch({
          type: ACTIONS.SET_USER_DATA,
          data: userData,
          token,
          expiration,
        })
      }
      return res
    })
    .catch(console.error)

export const logout = (dispatch, refreshPage = false) => {
  dispatch({ type: ACTIONS.LOGOUT, refreshPage })
}

export const rehydrateUserData = (dispatch) =>
  dispatch({ type: ACTIONS.REHYDRATE_USER_DATA })

export const resetPassword = (password, passwordResetId) => {
  return Api.put({
    url: '/Users/credentials/reset-password',
    data: {
      password: password,
      passwordResetId: passwordResetId,
    },
  }).catch(console.error)
}

export const setUserPassword = (userId, password, invitationId) => {
  return Api.put({
    url: '/Users/validate',
    data: {
      userId: userId,
      invitationId: invitationId,
      password: password,
      _dontShowErrorToast: true,
    },
  }).catch(console.error)
}

export const setUserPasswordForSales = (
  userId,
  fullName,
  password,
  phoneNumber,
  isRegulatoryComplianceAccepted = true,
  invitationId
) => {
  return Api.put({
    url: '/Users/validate',
    data: {
      phoneNumber: phoneNumber,
      fullName: fullName,
      userId: userId,
      invitationId: invitationId,
      password: password,
      isRegulatoryComplianceAccepted: isRegulatoryComplianceAccepted,
    },
  }).catch(console.error)
}

export const confirmUserEmailUpdate = (userGuid) =>
  Api.post({
    url: `/Users/email-update/${userGuid}/confirm`,
    data: userGuid,
  }).catch(console.error)

/**
 * Search for a user's current association so in case of a refresh token we can re-enrich the same association
 * If not found, 0 (the 1st association) is returned
 *
 * @param {Object} userData
 * @param {Array} associations
 * @returns {number}
 * @private
 */
const _findCurrentAssociationIndex = (userData, associations) => {
  if (!userData || !associations) {
    return 0
  }
  const idx = associations.findIndex(
    (el) =>
      el.organizationId === userData.organizationId &&
      el.role?.guid === userData.roleId
  )
  return idx >= 0 ? idx : 0
}
