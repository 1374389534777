import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { assets, components } from '@ElementsCapitalGroup/enium-ui'
import { formatAddress } from 'common/utils'
import AddCoBorrowerModal from 'modules/loan-application/select-loan/loan-blocked-modal/add-coborrower-modal'
import { updateApplicantsEmail } from 'modules/loan-application/actions'
import TextField, { INPUT_TYPES } from 'components/input'
import { VALIDATION_TYPES, validate } from 'components/validator'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button/index'
import { hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { useMediaQuery } from 'common/hooks'
import { DESKTOP_BREAKPOINT } from 'common/constants'

import styles from './style.module.scss'

const { Dialog, Dropdown } = components
const { PlusIcon } = assets

const BorrowersModal = ({
  isCommercialApp,
  borrowerDetails,
  coBorrowerDetails,
  serviceAddress,
  userData,
  loanData,
  setIsModalOpen,
  dispatch,
}) => {
  const { t: translate } = useTranslation()

  const canChangeOrg = hasAccess(userData, CLAIMS.CAN_CHANGE_DEALERS)
  const isTabletView = useMediaQuery(`(max-width:${DESKTOP_BREAKPOINT}px)`)

  const [borrowerEmail, setBorrowerEmail] = useState(
    borrowerDetails.emailAddress || ''
  )
  const [coBorrowerEmail, setCoBorrowerEmail] = useState(
    coBorrowerDetails?.emailAddress || ''
  )

  const [isLoading, setIsLoading] = useState(false)

  const [errors, setErrors] = useState({})

  useEffect(() => {
    setCoBorrowerEmail(coBorrowerDetails?.emailAddress)
  }, [coBorrowerDetails])

  const [isAddCoBorrowerOpen, setIsAddCoBorrowerOpen] = useState(false)

  const businessOwnerTranslation = translate(
    'loanApplication.commercial.businessOwner'
  )

  const handleSubmit = () => {
    const validationRules = {
      borrowerEmail: [VALIDATION_TYPES.REQUIRED, VALIDATION_TYPES.EMAIL],
    }

    if (coBorrowerDetails) {
      validationRules.coBorrowerEmail = [
        VALIDATION_TYPES.REQUIRED,
        VALIDATION_TYPES.EMAIL,
      ]
    }

    const [isValid, errors] = validate(validationRules, {
      borrowerEmail,
      coBorrowerEmail,
    })

    if (!isValid) {
      setErrors(errors)
    } else {
      const applicantsBasicInfo = [
        {
          emailAddress: borrowerEmail,
          applicantTypeId: borrowerDetails.applicantTypeId,
        },
        ...(coBorrowerDetails
          ? [
              {
                emailAddress: coBorrowerEmail,
                applicantTypeId: coBorrowerDetails.applicantTypeId,
              },
            ]
          : []),
      ]
      setIsLoading(true)

      updateApplicantsEmail(dispatch, {
        loanApplicationId: loanData.loanApplicationId,
        applicantsBasicInfo,
      })
        .then(() => setIsModalOpen(false))
        .finally(() => setIsLoading(false))
    }
  }

  const renderOwnersFullNames = () =>
    loanData.businessOwners.map((owner, index) => (
      <React.Fragment key={index}>
        <TextField
          label={`${businessOwnerTranslation} #${index + 1}`}
          disabled
          value={`${owner.firstName} ${owner.lastName}`}
          onChange={() => {}}
        />
      </React.Fragment>
    ))

  const renderUserName = (userDetails, isCoBorrower = false) => {
    const fullName = `${userDetails.firstName} ${userDetails.lastName}`

    return (
      <TextField
        label={translate(
          `loanApplication.statusCard.summaryModal.${
            isCoBorrower ? 'coborrowerName' : 'borrowerName'
          }`
        )}
        disabled
        value={fullName}
        onChange={() => {}}
      />
    )
  }

  return (
    <Dialog
      open={true}
      onClose={() => setIsModalOpen(false)}
      title={
        <div
          style={{ fontSize: '16px', paddingBottom: 0, marginBottom: '8px' }}
        >
          Borrower Details
        </div>
      }
      actions={
        <>
          <Button
            onClick={() => setIsModalOpen(false)}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            disabled={isLoading}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button disabled={!canChangeOrg || isLoading} onClick={handleSubmit}>
            {translate('buttons.save')}
          </Button>
        </>
      }
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '800px',
        },
      }}
      actionsProps={{
        sx: {
          paddingTop: 0,
        },
      }}
    >
      <div>
        <div className={styles.borrowerCard}>
          {isCommercialApp
            ? renderOwnersFullNames()
            : renderUserName(borrowerDetails)}

          <TextField
            label={translate(
              'loanApplication.statusCard.summaryModal.emailAddress'
            )}
            validate={() => errors.borrowerEmail}
            value={isCommercialApp ? loanData.emailAddress : borrowerEmail}
            disabled={!hasAccess(userData, [CLAIMS.CAN_EDIT_APPLICANT_EMAIL])}
            onChange={setBorrowerEmail}
          />

          <TextField
            label={translate(
              'loanApplication.statusCard.summaryModal.phoneNumber'
            )}
            value={
              isCommercialApp
                ? loanData.mobilePhone
                : borrowerDetails.phoneNumber
            }
            disabled
            type={INPUT_TYPES.PHONE}
            onChange={() => {}}
          />
          <TextField
            label={translate(
              'loanApplication.statusCard.summaryModal.serviceAddress'
            )}
            value={formatAddress(serviceAddress)}
            disabled
            onChange={() => {}}
          />
          {!isCommercialApp && (
            <Dropdown
              disabled
              options={[]}
              className="loan-form__dropdown"
              label={translate(
                'loanApplication.statusCard.summaryModal.preferredMethodOfCommunication'
              )}
              onChange={() => {}}
              value={{
                label: translate(
                  `loanApplication.step1.preferredMethodOfCommunication.${borrowerDetails.preferredCommunicationMethod.id}`
                ),
                id: borrowerDetails.preferredCommunicationMethod.id,
              }}
            />
          )}
        </div>
        {!coBorrowerDetails && (
          <Button
            startIcon={<PlusIcon />}
            variant={
              isTabletView ? BUTTON_VARIANTS.CONTAINED : BUTTON_VARIANTS.TEXT
            }
            color={
              isTabletView ? BUTTON_COLORS.SECONDARY : BUTTON_COLORS.PRIMARY
            }
            sx={{
              marginRight: { xs: 0, sm: '12px', lg: 0 },
              marginTop: 0,
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
            onClick={() => setIsAddCoBorrowerOpen(true)}
          >
            {translate('loanApplication.step1.addCoBorrower')}
          </Button>
        )}

        <AddCoBorrowerModal
          dispatch={dispatch}
          loanApplicationId={loanData.loanApplicationId}
          loanFormData={loanData}
          isOpen={isAddCoBorrowerOpen}
          setIsAddCoBorrowerOpen={setIsAddCoBorrowerOpen}
          loanSummary
          handleGetSelfServiceCriterias={() => {}}
          onClose={() => {
            setIsAddCoBorrowerOpen(false)
          }}
        />

        {coBorrowerDetails ? (
          <>
            <div
              className="applicant-info__form-title"
              style={{ marginBottom: '24px' }}
            >
              {translate('loanApplication.common.coborrowerDetails')}
            </div>
            <div className={styles.borrowerCard}>
              {renderUserName(coBorrowerDetails, true)}
              <TextField
                label={translate(
                  'loanApplication.statusCard.summaryModal.emailAddress'
                )}
                validate={() => errors.coBorrowerEmail}
                value={coBorrowerEmail}
                disabled={
                  !hasAccess(userData, [CLAIMS.CAN_EDIT_APPLICANT_EMAIL])
                }
                onChange={setCoBorrowerEmail}
              />

              <TextField
                label={translate(
                  'loanApplication.statusCard.summaryModal.phoneNumber'
                )}
                value={coBorrowerDetails.phoneNumber}
                disabled
                type={INPUT_TYPES.PHONE}
                onChange={() => {}}
              />
            </div>
          </>
        ) : null}
      </div>
    </Dialog>
  )
}

BorrowersModal.propTypes = {
  isCommercialApp: PropTypes.bool,
  borrowerDetails: PropTypes.object,
  coBorrowerDetails: PropTypes.object,
  serviceAddress: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  loanData: PropTypes.object.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default BorrowersModal
