import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { components, assets } from '@ElementsCapitalGroup/enium-ui'

import NTPRemoveStipulation from 'modules/loan-application/ntp/stipulations-remove'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { downloadFileBase64 } from 'common/utils'
import { CLAIMS } from 'common/claims'
import { AccessWrapper, hasAccess } from 'common/access'
import FileUploadV2 from 'components/file-upload-v2'
import {
  ATTACHMENT_STATUS_IDS,
  STATE_IDS,
} from 'modules/loan-application/constants'
import { resetPrefillHappenedFlag } from 'modules/global/actions'

import {
  downloadSingleAttachment,
  calculateIncomeToBeProven,
  uploadNTP,
  deleteUserNTP,
  setUserNTPStatus,
  deleteStipulationNTP,
} from '../actions'
import { initializeStripeIDVerification } from '../utils'
import { STIPULATION_CATEGORY_IDS } from '../constants'
import { UploadedStipulation } from './uploaded-stipulation'

import { styles } from './style'
import './style.scss'
import {
  areFundsGranted,
  getNtpStates,
  areNtpsApproved,
  areFundsRequested,
} from './helpers'

const { Paper, Tooltip, IconButton, Dialog } = components
const { TrashIcon } = assets

const NTPDocuments = ({
  ntps,
  commercialNTP,
  loanApplicationId,
  estimatedCombinedIncomeToBeProven,
  loading,
  setLoading,
  getNtps,
  serviceAddress,
  userData,
  updateUserNtpStatus,
  isInitial,
  loanApplicationStateId,
}) => {
  const { t: translate } = useTranslation()
  const [userNTPToDelete, setUserNTPToDelete] = useState(null)
  const [removeStipulationId, setRemoveStipulationId] = useState(null)
  const {
    dispatch,
    state: {
      global: { prefillHappened },
      flow: { possibleEditStates, possibleViewOnlyStates },
    },
  } = useStore()

  const canDeleteNTPsAfterApproval = hasAccess(
    userData,
    CLAIMS.CAN_DELETE_NTPS_AFTER_SUBMIT_FOR_APPROVAL
  )
  const canDeleteNTPs = hasAccess(userData, CLAIMS.CAN_DELETE_NTPS)

  const canSeeDecisioningButtons = hasAccess(
    userData,
    CLAIMS.CAN_UPDATE_USER_NTP_STATUS
  )

  const isEcg = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)
  const fundsRequested = areFundsRequested(
    loanApplicationStateId,
    isInitial,
    commercialNTP
  )
  const fundsGranted = areFundsGranted(
    loanApplicationStateId,
    isInitial,
    commercialNTP
  )
  const ntpApproved = areNtpsApproved(
    loanApplicationStateId,
    isInitial,
    commercialNTP
  )

  const [incomeToBeProven, setIncomeToBeProven] = useState(
    estimatedCombinedIncomeToBeProven
  )

  /** On status change */
  const handleStatusChange = (userNTPId, statusId) => {
    setLoading(true)
    setUserNTPStatus(userNTPId, { statusId })
      .then(updateUserNtpStatus)
      .finally(() => setLoading(false))
  }

  /** On Download */
  const handleDownloadAttachment = (attachmentId) => {
    downloadSingleAttachment(dispatch, attachmentId).then(
      (res) => res && downloadFileBase64(res)
    )
  }

  /** On Delete */
  const handleOnClickDelete = () => {
    const { userNTPId } = userNTPToDelete
    setUserNTPToDelete(null)
    setLoading(true)
    deleteUserNTP(dispatch, loanApplicationId, userNTPId)
      .then(getNtps)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (
      loanApplicationId &&
      !estimatedCombinedIncomeToBeProven &&
      !commercialNTP &&
      ntps.find(
        (item) =>
          item.stipulationDefinition.additionalInfoId ===
          STIPULATION_CATEGORY_IDS.VERIFICATION_OF_INCOME
      )
    ) {
      calculateIncomeToBeProven(loanApplicationId).then((res) =>
        setIncomeToBeProven(res)
      )
    }
  }, [loanApplicationId, estimatedCombinedIncomeToBeProven])

  const viewOnly = useMemo(() => {
    const ntpStates = getNtpStates(isInitial, commercialNTP)

    return (
      loanApplicationStateId?.id === STATE_IDS.ApplicationExpired ||
      (possibleViewOnlyStates.some((s) => ntpStates.includes(s)) &&
        !possibleEditStates.some((s) => ntpStates.includes(s)))
    )
  }, [
    possibleEditStates,
    possibleViewOnlyStates,
    loanApplicationStateId,
    isInitial,
    commercialNTP,
  ])

  /** Initialize Stripe ID verification and wait for the response in the following useEffect */
  const handleOnClickScan = (applicantId) => () => {
    initializeStripeIDVerification(dispatch, applicantId)
  }

  /** Once prefill happens, re-fetch NTPs */
  useEffect(() => {
    if (prefillHappened) {
      resetPrefillHappenedFlag(dispatch)
      getNtps()
    }
  }, [prefillHappened])

  const ntpList = ntps.map((item, index) => {
    const canUpload =
      (canDeleteNTPsAfterApproval || !ntpApproved) &&
      (isEcg || !fundsRequested) &&
      !fundsGranted
    const isScanIDEnabled =
      isScanEnabled(item, serviceAddress, commercialNTP) && canUpload
    const displayIncomeToBeProven =
      item.stipulationDefinition.additionalInfoId ===
      STIPULATION_CATEGORY_IDS.VERIFICATION_OF_INCOME

    const canUploadClaim = hasAccess(
      userData,
      isScanIDEnabled
        ? CLAIMS.CAN_UPLOAD_ID_STIPULATIONS
        : CLAIMS.CAN_UPLOAD_STIPULATIONS
    )

    return (
      <Paper
        className="paper paper--no-shadow-box"
        sx={styles.documentCard}
        key={item.applicationNTPId}
      >
        <div>
          {(canDeleteNTPsAfterApproval || !ntpApproved) && (
            <AccessWrapper
              claims={
                isScanIDEnabled
                  ? CLAIMS.CAN_UPLOAD_ID_STIPULATIONS
                  : CLAIMS.CAN_UPLOAD_STIPULATIONS
              }
            >
              <div style={styles.cardTitle}>
                <div style={styles.cardTitleContent}>
                  {displayIncomeToBeProven && incomeToBeProven && (
                    <div style={{ fontWeight: 'initial' }}>
                      {`$ ${incomeToBeProven.toFixed(2)}`}
                    </div>
                  )}

                  {/* Remove entire Stipulation row */}
                  {!item.isFromTemplate && (
                    <AccessWrapper claims={CLAIMS.CAN_DELETE_STIPULATIONS}>
                      <Tooltip
                        title={translate(
                          'loanApplication.ntpStepsCommon.removeStipulationTitle'
                        )}
                      >
                        <IconButton rounded={true} disabled={viewOnly}>
                          <TrashIcon
                            onClick={() =>
                              setRemoveStipulationId(item.applicationNTPId)
                            }
                          />
                        </IconButton>
                      </Tooltip>
                    </AccessWrapper>
                  )}
                </div>
              </div>
            </AccessWrapper>
          )}
          <FileUploadV2
            key={item.applicationNTPId}
            onChange={(file) => {
              setLoading(true)
              uploadNTP(item.applicationNTPId, file)
                .then(getNtps)
                .finally(() => setLoading(false))
            }}
            onScanIdClick={(e) => handleOnClickScan(item.applicantId)(e)}
            shouldDisplayScanButton={isScanIDEnabled}
            canUpload={canUpload && canUploadClaim}
            title={
              <>
                {translate(
                  `loanApplication.ntpStepsCommon.stipulations.${item.stipulationDefinition.guid}.name`
                )}
                {item.applicantName && ` - ${item.applicantName}`}
              </>
            }
            uploadFunctionSettings={{
              method: 'PUT',
              url: `${process.env.REACT_APP_HOST_LENDER_V3}/LoanApplication/upload-user-ntp`,
              data: { applicationNTPId: item.applicationNTPId },
              cb: getNtps,
            }}
          />
        </div>
        <div className="ntp__documents-wrapper">
          {item.userNTPs.map((userNTP) => (
            <div key={userNTP.userNTPId}>
              <UploadedStipulation
                ntp={userNTP}
                stipulationIndex={index}
                setUserNTPToDelete={setUserNTPToDelete}
                canDeleteNTP={canDeleteNTPs}
                canDeleteNTPsAfterApproval={canDeleteNTPsAfterApproval}
                canSeeDecisioningButtons={
                  canSeeDecisioningButtons && (!fundsGranted || isEcg)
                }
                ntpApproved={ntpApproved}
                onNeedsReview={() => {
                  handleStatusChange(
                    userNTP.userNTPId,
                    ATTACHMENT_STATUS_IDS.NEEDS_REVIEW
                  )
                }}
                onApprove={() => {
                  handleStatusChange(
                    userNTP.userNTPId,
                    ATTACHMENT_STATUS_IDS.APPROVED
                  )
                }}
                onReject={() => {
                  handleStatusChange(
                    userNTP.userNTPId,
                    ATTACHMENT_STATUS_IDS.DECLINED
                  )
                }}
                onDownloadAttachment={handleDownloadAttachment}
                viewOnly={viewOnly}
              />
            </div>
          ))}
        </div>
      </Paper>
    )
  })

  return (
    <div className={cx('ntp__documents', { disabled: loading })}>
      <Dialog
        open={!!userNTPToDelete}
        title={translate(
          'loanApplication.ntpStepsCommon.removeFileConfirmation'
        )}
        onClose={() => setUserNTPToDelete(null)}
        actions={
          <>
            <Button
              onClick={() => setUserNTPToDelete(null)}
              color={BUTTON_COLORS.INHERIT}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button onClick={handleOnClickDelete}>
              {translate('buttons.yes')}
            </Button>
          </>
        }
      />
      <NTPRemoveStipulation
        stipulationId={removeStipulationId}
        isOpen={!!removeStipulationId}
        onClose={() => setRemoveStipulationId(null)}
        onRemoveStipulation={(applicationNTPId) => {
          setLoading(true)
          setRemoveStipulationId(null)
          deleteStipulationNTP(dispatch, applicationNTPId, loanApplicationId)
            .then(getNtps)
            .finally(() => {
              setLoading(false)
            })
        }}
      />

      {ntpList}
    </div>
  )
}

function isScanEnabled(stipulation, serviceAddress, commercialNTP) {
  return (
    stipulation.stipulationDefinition.additionalInfoId ===
      STIPULATION_CATEGORY_IDS.ID_SCAN &&
    serviceAddress?.state !== 'VI' &&
    !commercialNTP
  )
}

NTPDocuments.propTypes = {
  ntps: PropTypes.array.isRequired,
  commercialNTP: PropTypes.bool,
  loanApplicationId: PropTypes.string.isRequired,
  estimatedCombinedIncomeToBeProven: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  getNtps: PropTypes.func.isRequired,
  serviceAddress: PropTypes.object,
  userData: PropTypes.object.isRequired,
  updateUserNtpStatus: PropTypes.func,
  isInitial: PropTypes.bool.isRequired,
  loanApplicationStateId: PropTypes.number.isRequired,
}

export default NTPDocuments
