import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components } from '@ElementsCapitalGroup/enium-ui'
import { formatInUSFormat } from 'common/number'
import TextField from 'components/input'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import { updateLoanAmount } from '../../actions'

const { Dialog } = components

const ChangeAmountModal = ({
  dispatch,
  loanApplicationId,
  isOpen,
  onClose,
  maxAmount,
  handleGetSelfServiceCriterias,
}) => {
  const { t: translate } = useTranslation()
  const [loanAmount, setLoanAmount] = useState(maxAmount)
  const [loading, setLoading] = useState(false)

  const handleOnClose = (event, reason) => {
    // Disable on background click for closing the Dialog
    if (reason !== 'closeButtonClicked') {
      return
    }
    onClose()
  }

  const handleSubmit = () => {
    setLoading(true)
    updateLoanAmount(dispatch, loanApplicationId, loanAmount).finally(() => {
      handleGetSelfServiceCriterias()
      setLoading(false)
    })
  }

  return (
    <Dialog
      open={isOpen}
      title={translate('loanApplication.blocked.changeLoanAmount')}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '400px',
          '& .MuiDialogTitle-root': {
            alignItems: 'center',
            fontSize: '1.125rem',
          },
        },
      }}
      disableEscapeKeyDown={true}
      onClose={handleOnClose}
      actions={
        <>
          <Button
            onClick={onClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            disabled={loading}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={!loanAmount}
            loading={loading}
            onClick={handleSubmit}
          >
            {translate('global.saveChanges')}
          </Button>
        </>
      }
      actionsProps={{
        sx: {
          paddingTop: '8px',
          width: '100%',
          justifyContent: 'space-between',
          '& button': {
            width: '50%',
          },
        },
      }}
    >
      {maxAmount && (
        <div className="loan-blocked__text">
          {translate('loanApplication.blocked.loanAmount2')} $
          {formatInUSFormat(maxAmount, 0)}. &nbsp;
          {translate('loanApplication.blocked.loanAmount3')}
        </div>
      )}

      <TextField
        style={{ marginTop: maxAmount ? '24px' : 0 }}
        label={translate('loanApplication.step1.loanAmount')}
        prefix="$"
        type="currency"
        fixedDecimalScale={true}
        value={loanAmount}
        onChange={setLoanAmount}
        className="grid-item"
        fullWidth={true}
        disabled={loading}
      />
    </Dialog>
  )
}

ChangeAmountModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  maxAmount: PropTypes.number.isRequired,
  handleGetSelfServiceCriterias: PropTypes.func.isRequired,
}

export default ChangeAmountModal
